



































































































































































































import Vue from "vue";
import ViewAllInterviewers from "@/components/recruiter/interview/ViewAllInterviewers.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import {
  INTERVIEW_LISTING,
  INTERVIEW_OBJECT,
  INTERVIEW_SEARCHED_JOB,
  RECRUITER_INTERVIEW_SEARCH_JOB_LOADING,
  UPDATE_INTERVIEW_PAGINATION,
  SELECTED_CANDIDATE_INTERVIEW_REPORT,
  INTERVIEW_ACTION
} from "@/store/modules/recruiter/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import {
  IndividualInterviewHistory,
  InterviewActions,
  InterviewListingCandidates,
  InterviewListingPayload,
  InterviewListingResult
} from "@/store/modules/recruiter/interfaces";
import moment from "moment";
import ListingCandidates from "@/components/recruiter/interview/ListingCandidates.vue";
import {
  get_interview_status_class,
  get_interview_status_msg
} from "@/utils/interviews";
import { generate_random_key } from "@/utils/global";
import ViewCandidateInterviewHistory from "@/components/recruiter/interview/ViewCandidateInterviewHistory.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "InterviewListingBody",
  components: {
    ViewCandidateInterviewHistory,
    ListingCandidates,
    DataNotFound,
    ViewAllInterviewers
  },
  data() {
    return {
      report_payload: [] as InterviewListingCandidates[],
      view_all_interviewers: false,
      loading: false,
      total: 0,
      view_interview_history: false,
      individual_interview_details: null as null | IndividualInterviewHistory,
      interview_details: null as null | InterviewListingResult,
      selected_key: null as null | number
    };
  },
  watch: {
    get_searched_job(n) {
      if (n) {
        this.process_searched_job();
      } else {
        this.update_interview_pagination(1);
        this.process_interview_listing();
      }
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    }),
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      get_interview_listing: INTERVIEW_LISTING,
      get_searched_job_loading: RECRUITER_INTERVIEW_SEARCH_JOB_LOADING,
      get_searched_job: INTERVIEW_SEARCHED_JOB,
      get_interview_object: INTERVIEW_OBJECT
    })
  },
  async mounted() {
    if (this.get_searched_job) await this.process_searched_job();
    else
      await this.process_interview_listing(
        this.get_interview_object.pagination
      );
  },
  methods: {
    generate_random_key,
    ...mapActions("recruiter", {
      fetch_interview_listing: INTERVIEW_LISTING,
      interview_action: INTERVIEW_ACTION
    }),
    ...mapMutations("recruiter", {
      set_interview_listing: INTERVIEW_LISTING,
      set_search_job_loading: RECRUITER_INTERVIEW_SEARCH_JOB_LOADING,
      update_interview_pagination: UPDATE_INTERVIEW_PAGINATION,
      set_selected_candidate: SELECTED_CANDIDATE_INTERVIEW_REPORT
    }),
    async process_interview_listing(page = 1, limit = 10) {
      this.loading = true;
      // Fetching interview listing
      const listing = await this.fetch_interview_listing({
        page: page - 1,
        limit: limit,
        interviewed_by: this.get_user.id
      });
      // If listing found
      const finalObj = new Map<number, InterviewListingResult>();
      if (listing) {
        this.total = listing.total;
        for (let interview of listing.results) {
          const final_data: InterviewListingResult = {
            id: interview.job_id,
            title: interview.job_title,
            zappy_job_id: interview.zappy_job_id,
            date: moment(interview.created_at).format("LL"),
            candidates: interview.candidates.map(
              (candidate: InterviewListingCandidates) => {
                return {
                  ...candidate,
                  interview_id: candidate.id,
                  class: get_interview_status_class(candidate.interview_status),
                  readable_status: get_interview_status_msg(
                    candidate.interview_status,
                    this.get_user.role_id
                  )
                };
              }
            )
          };
          finalObj.set(interview.job_id, final_data);
        }
        this.set_interview_listing(finalObj);
      } else await this.$router.push("/recruiter/dashboard");
      this.loading = false;
    },
    /**
     * FUnction to process when job is searched
     */
    async process_searched_job() {
      this.set_search_job_loading(true);
      const searched_job = this.get_searched_job;
      const finalObj = new Map<number, InterviewListingResult>();
      // Fetching all candidates of searched job
      const payload: InterviewListingPayload = {
        interviewed_by: this.get_user.id,
        job_id: searched_job.job_id
      };
      const interview = await this.fetch_interview_listing(payload);
      this.total = interview.length;
      if (interview.length > 0) {
        const final_data: InterviewListingResult = {
          id: interview[0].job_id,
          title: interview[0].job_title,
          zappy_job_id: interview.zappy_job_id,
          date: moment(interview[0].created_at).format("LL"),
          candidates: []
        };
        for (let inter of interview) {
          const obj: InterviewListingCandidates = {
            ...inter,
            interview_id: inter.id,
            id: inter.candidate_id,
            class: get_interview_status_class(inter.interview_status),
            readable_status: get_interview_status_msg(
              inter.interview_status,
              this.get_user.role_id
            )
          };
          final_data.candidates.push(obj);
        }
        finalObj.set(searched_job, final_data);
      }
      this.set_interview_listing(finalObj);
      this.set_search_job_loading(false);
    },
    view_all_reports(interview: InterviewListingResult, key: number) {
      this.report_payload = interview.candidates;
      this.interview_details = interview;
      this.selected_key = key;
      this.view_all_interviewers = true;
    },
    close_interview_history_popup() {
      this.set_selected_candidate([]);
      this.view_interview_history = false;
      this.individual_interview_details = null;
    },
    view_candidate_interview_report(details: IndividualInterviewHistory) {
      this.individual_interview_details = details;
      this.view_interview_history = true;
      this.set_selected_candidate([]);
    },
    async delete_or_reset_candidate_report(payload: {
      candidate: InterviewListingCandidates;
      action: string;
    }) {
      const payload_data = {
        candidate_id: payload.candidate.candidate_id,
        interview_id: payload.candidate.interview_id,
        action:
          payload.action === "reset"
            ? InterviewActions.REDO
            : InterviewActions.REMOVED
      };

      const response = await this.interview_action(payload_data);
      if (response) {
        await this.process_interview_listing(
          this.get_interview_object.pagination
        );
        const interview = this.get_interview_listing.get(this.selected_key);
        if (interview && this.selected_key) {
          this.view_all_interviewers = false;
          this.view_all_reports(interview, this.selected_key);
        } else {
          this.view_all_interviewers = false;
          this.selected_key = null;
        }
      }
      const view_all_interviewers = this.$refs
        .view_all_interviewers as InstanceType<typeof ViewAllInterviewers>;
      if (view_all_interviewers) {
        view_all_interviewers.loading = false;
        view_all_interviewers.delete_dialog;
      }
    }
  }
});
