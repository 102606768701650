





























































































































































import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import DeleteItem from "@/components/shared/DeleteItem.vue";
import {
  InterviewHistoryData,
  InterviewListingCandidates
} from "@/store/modules/recruiter/interfaces";
import { generate_random_key, get_name_initials } from "@/utils/global";
import {
  INTERVIEW_OBJECT,
  SELECTED_CANDIDATE_INTERVIEW_REPORT
} from "@/store/modules/recruiter/constants";
import { Interview } from "@/interfaces/responses/interviews/interviews";

export default Vue.extend({
  name: "ViewAllInterviewers",
  components: {
    DeleteItem
  },
  data() {
    return {
      history_data: {} as InterviewHistoryData,
      delete_icon: require("@/assets/icons/bold/trash.svg"),
      retry_icon: require("@/assets/icons/bold/reset.svg"),
      document_download: require("@/assets/icons/linear/document-download.svg"),
      delete_dialog: false,
      candidate_data: {} as InterviewListingCandidates,
      action: "",
      loading: false
    };
  },
  computed: {
    Interview() {
      return Interview;
    },
    ...mapGetters("auth", {
      user: GET_USER_DETAILS
    }),
    ...mapGetters("recruiter", {
      get_selected_candidate: SELECTED_CANDIDATE_INTERVIEW_REPORT,
      get_interviews: INTERVIEW_OBJECT
    }),
    get_action_title(): string {
      return this.action === "delete"
        ? this.$t("recruiter.interview.delete-interview-title").toString()
        : this.$t("recruiter.interview.reset-interview-title").toString();
    },
    get_button_text(): string {
      return this.action === "delete"
        ? this.$t("recruiter.interview.delete-interview").toString()
        : this.$t("recruiter.interview.reset-interview").toString();
    }
  },
  props: {
    candidates: {
      type: Array as PropType<InterviewListingCandidates[]>,
      required: true
    }
  },
  async mounted() {
    if (this.get_selected_candidate?.interview_id) {
      const history = this.candidates.find(
        (val: InterviewListingCandidates) =>
          val.interview_id === this.get_selected_candidate.interview_id
      );
      if (!history) return;
      this.$emit("individual_candidate_report", {
        candidate_interview_history: history.content,
        name:
          this.get_selected_candidate.first_name +
          " " +
          this.get_selected_candidate.last_name,
        avatar: this.get_selected_candidate.avatar_uri,
        candidate_id: this.get_selected_candidate.candidate_id,
        zappy_job_id: this.get_selected_candidate.zappy_job_id,
        job_id: this.get_selected_candidate.job_id
      });
    }
  },
  methods: {
    generate_random_key,
    name_initials(user: InterviewListingCandidates) {
      const name = user.first_name + user.last_name;
      return get_name_initials(name);
    },
    view_candidate_interview_report(candidate: InterviewListingCandidates) {
      const history = candidate.content;
      this.$emit("individual_candidate_report", {
        candidate_interview_history: history,
        name: candidate.first_name + " " + candidate.last_name,
        avatar: candidate.avatar_uri,
        candidate_id: candidate.candidate_id,
        zappy_job_id: candidate.zappy_job_id,
        job_id: candidate.job_id
      });
    },
    transform_icon(icon: HTMLElement, color: string): HTMLElement {
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    delete_or_reset_candidate_report(
      candidate: InterviewListingCandidates,
      action: string
    ) {
      this.delete_dialog = true;
      this.candidate_data = candidate;
      this.action = action;
    },
    // Dialog Action of reset or Delete Button
    dialog_action(value: boolean) {
      if (value) {
        this.loading = true;
        this.$emit("delete_or_reset_candidate_report", {
          candidate: this.candidate_data,
          action: this.action
        });
      }
    }
  }
});
